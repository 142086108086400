
import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";


const AddresesList = (props) => {

    const {t} = useTranslation();

    return (


        <div className="card">

            <header className="card-header">
                <p className="card-header-title">
                    {t('added_addresses')}
                </p>

            </header>

            <div className="card-content">

                <div className="content">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>{t('address')}</th>
                            <th>{t('actions')}</th>

                        </tr>
                        </thead>

                        <tbody>

                        {props.addresses.map(address => (
                            <tr key={address.id}>
                                <td>{address.formatted_address}</td>
                                <td><button value={address.id} onClick={(e) => props.deleteAddress(e.target.value)}  className={'button is-danger'}>{t('delete')}</button></td>
                            </tr>
                        ))}


                        </tbody>
                    </table>
                </div>
            </div>
        </div>



    );
};

export default AddresesList;