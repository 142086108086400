import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import {useEffect, useState} from "react";
import  {isLoggedIn} from "../utils/auth.service";
import {graphql, navigate} from "gatsby";
import authHeader from "../utils/auth-header";
import AddresesList from "../components/addresses/list";
import LoadingSpinner from "../components/loading-spinner";
import toast, {Toaster} from 'react-hot-toast';
import Popup from "../components/popup";
import {useTranslation} from "gatsby-plugin-react-i18next";


const Vehicles = () => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [inputAddress, setInputAddress] = useState("");
    const [addresses, setAddresses] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(false);


    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
    });


    useEffect(() => {

        if (!isLoggedIn()) {
            return navigate('/');
        }
        loadAddresses();


    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            setSubmitDisabled(true);
            e.target.disabled = true


            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    address: inputAddress,
                    category_id: 2

                })
            };


            fetch(process.env.WASHCAR_API_URL + "/customer-address/store", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {

                    if (resultData.status === 'Error') {
                        toast.error(resultData.alert.text)
                    } else {

                        //@todo can with out fetch????
                        toast.success(resultData.alert.text);
                        loadAddresses();
                        setInputAddress("");
                    }

                    setSubmitDisabled(false);


                }) // set data for the number of stars

        } catch (err) {
            console.log(err);
        }

    };


    const loadAddresses = () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        setLoading(true);

        fetch(process.env.WASHCAR_API_URL + "/business/my-addresses/", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setLoading(false);
                setAddresses(resultData.data);
            }) //
    }

    const deleteAddress = async (addressID) => {


        setPopup({
            show: true,
            addressID,
        });

    };


    const handleDeleteTrue = () => {



        if (popup.show && popup.addressID) {



            try {

                let filteredArray = addresses.filter(item => item.id !== popup.addressID)
                setAddresses(filteredArray);

                const requestOptions = {
                    method: 'POST',
                    headers: authHeader(),
                };

                fetch(process.env.WASHCAR_API_URL + "/customer-address/delete-address/" + popup.addressID, requestOptions)
                    .then(response => response.json()) // parse JSON from request
                    .then(resultData => {
                        toast.success(resultData.alert.text);
                    })


            } catch (err) {
                console.log(err);
            }



            setPopup({
                show: false,
                id: null,
            });
        }
    };

// This will just hide the Confirmation Box when user clicks "No"/"Cancel"

    const handleDeleteFalse = () => {

        setPopup({
            show: false,
            id: null,
        });
    };


    return (
        <Layout>

            <Seo title={t('addresses')}/>

            <h1 className="title">{t('addresses')}</h1>

            {popup.show ?  <Popup
                handleDeleteTrue={handleDeleteTrue}
                handleDeleteFalse={handleDeleteFalse}
            /> : null}



            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            {loading ? <div className="pos-center"><LoadingSpinner/></div> : <div>

                {addresses.length === 0 ? <p className={'box notification is-warning'}> {t('no_addresses_found_text')}</p> :
                    <AddresesList addresses={addresses} deleteAddress={deleteAddress}/>}


            </div>}


            <div className="card mt-5">

                <header className="card-header">
                    <p className="card-header-title">
                        {t('add_new_address')}
                    </p>

                </header>

                <div className="card-content">

                    <div className="content">
                        <form onSubmit={handleSubmit}>
                            <div className="field">
                                <label className="label">{t('address')}</label>
                                <div className="control">
                                    <input required className="input" type="text" placeholder={t('enter_address')}
                                           value={inputAddress} onChange={(e) => setInputAddress(e.target.value)}/>

                                </div>
                            </div>


                            <div className="field is-grouped">
                                <div className="control">
                                    <button disabled={submitDisabled} type="submit"
                                            className="button is-link">{t('add')}
                                    </button>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            </div>


        </Layout>
    )
}

export default Vehicles

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;